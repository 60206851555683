.react-dropdown-select.dropdown {
  position: relative;
  cursor: pointer;
  outline: none;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  border: 0;
  align-items: center;
  -webkit-align-items: center;
  -webkit-transition: box-shadow .15s ease;
  transition: box-shadow .15s ease;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  width: 100%;
  line-height: 1.5;
  color:#8898aa;
  background-color:#fff;
  background-clip: padding-box;
  border-radius: 0.375rem;
  font-weight: 400;
  font-size: 0.875rem;

  .react-dropdown-select-input {
    color:#8898aa;
    font-weight: 400;
    font-size: 0.875rem;
    padding-left: 1px;
  }

  div[role="list"] {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    margin-top: 10px;
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
    border: 0;
    -webkit-transition: box-shadow .15s ease;
    transition: box-shadow .15s ease;
    border-radius: 0.375rem;

    span:first-child{
      border-radius: 0.375rem 0.375rem 0 0;
      }

    span:last-child{
      border-radius: 0 0 0.375rem 0.375rem;
    }

    span {
      display: block;
      padding: 15px;
      background-color: #fff;
      margin-bottom: 0 !important;
      color:#8898aa;
      font-weight: 400;
      font-size: 0.875rem;
      
      &:hover,
      &:focus {
        color: #546c84;
        background-color: #fbfbfb;
      }
    }
  }

  .react-dropdown-select-no-data {
    display: none !important;
  }
  
  .react-dropdown-select-content {
    height: 23px !important;
    overflow: hidden !important;
  }

  .react-dropdown-select-type-multi {
    height: 28px !important;
    overflow: hidden !important;
  }

  .react-dropdown-select-item-selected {
    background: #5e72e4 !important;
    color: #fff !important;
    }

  .react-dropdown-select-dropdown-handle {
    height: 36px !important;
    transition: 0.2s ease;

    svg {
      width: 30px !important;
      height: 30px !important;

      path{
        stroke: none !important;
      }
    }
  }
}

.react-dropdown-select.dropdown.react-dropdown-create {
  .react-dropdown-select-item {
    display: none !important;
  }
}

.input-group-button {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

   .form-group {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    margin-right: 15px;
   }

   .btn{
    height:46px;
    margin-top: 32px;
  }
}